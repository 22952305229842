<template>
  <div class="view-container">
    <div class="card display--flex subscription_card">
      <div>
        <div class="card-header card-header-divider">
          <div class="card-title">
            <p>Управление подпиской</p>
          </div>
        </div>
        <div>
          <div class="page-preloader" v-if="isPreloader">
            <mini-preloader/>
          </div>
          <div v-else-if="!isPreloader && subscriptionSummary.length" class="card-body subscription_card">
            <div v-if="ownSubscription.length" class="subscription_wrapper">
              <h3>Ваши подписки</h3>
              <ul>
                <li v-for="subscription in ownSubscription" :key="subscription.id" class="subscription_detail">
                  <p class="subscription_header">{{ subscription.title }}</p>
                  <div class="subscription_duration">
                    <p>Дата активации: <span>{{ subscription.activated_at }}</span></p>
                    <p>Истекает: <span>{{ subscription.expired_at }}</span></p>
                  </div>
                  <div>
                    <table class="limits_table table-sm w-full">
                      <thead>
                      <tr>
                        <th>Продукт</th>
                        <th>Использовано</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(product, index) of subscription.products" :key="index">
                        <td>
                          {{ product.title }}
                        </td>
                        <td>
                          <span class='text_accent'>{{ product.issuedCount }}</span> из {{ product.totalCount }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <ul class="limits_card">
                      <li v-for="(product, index) in subscription.products" :key="index" class="limits_block">
                        <p>{{ product.title }}</p>
                        <div class="limits_count">
                          <span class='text_accent'>{{ product.issuedCount }}</span> из {{ product.totalCount }}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div v-if="subscription.owned" class="access_settings">
                    <div>
                      <button class="btn btn-accent" @click="() => showAccessForm(subscription.id)">
                        {{ showSettings === subscription.id ? 'Скрыть' : 'Настроить доступ' }}
                      </button>
                    </div>
                    <template v-if="showSettings === subscription.id">
                      <div v-if="loadAccess" class="companies_select">
                        <custom-select-2
                            name="companies"
                            id="companySelect"
                            :multiple="true"
                            v-model="form.companies"
                            :options="companyOptions"
                        >
                        </custom-select-2>
                        <div class="access_button">
                          <button class="btn btn-accent" @click="() => grantAccess(subscription.id)">Сохранить</button>
                        </div>
                      </div>
                      <div v-else class="access_loader">
                        <mini-preloader/>
                      </div>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="sharedSubscription.length" class="subscription_wrapper">
              <h3>Подписки, к которым у вас есть доступ</h3>
              <ul>
                <li v-for="subscription in sharedSubscription" :key="subscription.id">
                  <p class="subscription_header">{{ subscription.title }}</p>
                  <div class="subscription_duration">
                    <p>Дата активации: <span>{{ subscription.activated_at }}</span></p>
                    <p>Истекает: <span>{{ subscription.expired_at }}</span></p>
                  </div>
                  <div>
                    <table class="limits_table table-sm w-full">
                      <thead>
                      <tr>
                        <th>Продукт</th>
                        <th>Использовано</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(product, index) of subscription.products" :key="index">
                        <td>
                          {{ product.title }}
                        </td>
                        <td>
                          <span class='text_accent'>{{ product.issuedCount }}</span> из {{ product.totalCount }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <ul class="limits_card">
                      <li v-for="(product, index) in subscription.products" :key="index" class="limits_block">
                        <p>{{ product.title }}</p>
                        <div class="limits_count">
                          <span class='text_accent'>{{ product.issuedCount }}</span> из {{ product.totalCount }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="card-body">
            <div class="no_results">
              <p>Нет активных подписок</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onBeforeMount} from "@vue/runtime-core";
import {useStore} from "vuex";
import {computed, ref} from "@vue/reactivity";
import Preloader from "@/App.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";
import useCheckRights from "@/mixins/useCheckRights";
import router from "@/router";

export default {
  name: "Subscription.vue",
  components: {MiniPreloader, CustomSelect2, Preloader},
  setup() {
    const store = useStore();
    const {getRight} = useCheckRights();
    const isPreloader = ref(false);
    const loadAccess = ref(false);
    const showSettings = ref(null);
    const subscriptionSummary = computed(() => store.state.subscription.subscriptionSummary);
    const permittedCompanies = computed(() => store.state.subscription.permittedCompanies);
    const userCompany = computed(() => store.state.company.userCompany);
    const companies = computed(() => store.state.company.companies);
    const companyOptions = computed(() => {
      return companies.value
          .filter(company => company.id !== userCompany.value.id)
          .map(company => ({id: company.id, text: company.name}));
    });

    const ownSubscription = computed(() => subscriptionSummary.value.filter((el) => el.owned));
    const sharedSubscription = computed(() => subscriptionSummary.value.filter((el) => !el.owned));

    const form = ref({
      companies: [],
    });

    const showAccessForm = (id) => {
      if (showSettings.value === id) {
        showSettings.value = null;
      } else {
        loadAccess.value = false;
        showSettings.value = id;
        store.dispatch('subscription/getPermittedCompanies', id).then(() => {
            permittedCompanies.value.forEach((company) => form.value.companies.push(company.id))
            loadAccess.value = true;
          })
      }
    };

    const grantAccess = (id) => {
      store.dispatch('subscription/setSubscriptionAccess', {
        'subscription_id': id,
        'companies': [userCompany.value.id, ...form.value.companies],
      });
    }

    onBeforeMount(async () => {
      isPreloader.value = true;
      await store.dispatch("profile/getProfile").then(() => {
        if (!getRight('subscription')) {
          router.push('/');
        }
      });
      await store.dispatch("company/getCompanies");
      store.dispatch("company/getUserCompany").then(() => {
        if (userCompany.value?.id) {
          store.dispatch("subscription/getSubscriptionSummary", userCompany.value?.id).then(() => {
            isPreloader.value = false;
          });
        }
      }).catch(() => {
        isPreloader.value = false;
      });
    });

    return {
      isPreloader,
      subscriptionSummary,
      ownSubscription,
      sharedSubscription,
      form,
      companies,
      companyOptions,
      showAccessForm,
      getRight,
      grantAccess,
      showSettings,
      loadAccess
    }
  },
}
</script>


<style scoped lang="scss">

.access_settings {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.access_loader {
  padding: 20px 0;
  text-align: center;
}

.companies_select {
  margin-top: 15px;
  display: flex;
  align-items: center;
  row-gap: 15px;
}

.companies_select .form-group {
  padding-left: 5px;
  justify-content: flex-start;
  width: 100%;
}

.expires {
  color: #4d9477;
}

.limits_block {
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
}

.limits_count {
  min-width: 20%;
}

.limits_card {
  display: none;
}

.subscription_card {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.subscription_header,
.subscription_duration {
  margin-bottom: 10px;
}

.subscription_card {
  min-height: 500px;
}

.subscription_duration {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.subscription_header {
  font-size: 18px;
}

.subscription_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.text_accent {
  color: #00a65a;
}

@media (max-width: 600px) {
  .access_button {
    margin-left: auto;
  }

  .companies_select {
    flex-direction: column;
  }

  .companies_select .form-group {
    align-self: flex-start;
    width: 100%;
  }

  .limits_table {
    display: none;
  }

  .limits_card {
    display: block;
  }
}
</style>